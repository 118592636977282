.public-page_dark {
  background: #000000;
  .public-page__header {
    background: #121212;
    border-color: #262626; }
  .public-page__header-content__item__description {
    color: #CACACA; }
  .public-page__header-content__item {
    color: #FFFFFF; }
  .public-page__header-content__locales__item {
    color: #888888; }
  .public-page__header-content__locales__item--active {
    color: #FFFFFF; }
  .public-page__header-content__info-block-link {
    color: #FFFFFF; }
  .public-page-content__block-item {
    background: #121212;
    border-color: #262626;
    color: #FFFFFF; }
  .public-page-content__block-title__text {
    color: #CACACA; }
  .public-page-content__block-title__text--active {
    color: #FFFFFF; }
  .public-page__player {
    background: #121212; }
  .public-page-button-icon__path {
    fill: #FFFFFF; }
  .public-page-footer__text {
    color: #888888; }
  .public-page__player-control__path {
    fill: #888888; }
  .public-page__player__button {
    color: #888888;
    border-color: #888888; }
  .public-page__player__button:hover {
    color: #FFFFFF;
    border-color: #FFFFFF; }
  .public-page__player__streams {
    box-shadow: rgba(0, 0, 0, 1) 0px 0px 24px;
    background: #2B2B2B; }
  .public-page__player__volume__triangle {
    border-color: #2B2B2B transparent transparent transparent; }
  .public-page__player__streams__item--active {
    color: #FFFFFF; }
  .public-page__player__volume {
    background: #2B2B2B;
    box-shadow: rgba(0, 0, 0, 1) 0px 0px 24px; }
  .public-page__player__info-artist {
    color: #FFFFFF; }
  .public-page__player__info-title {
    color: #CACACA; }
  .public-page__modal__content {
    background: #1e1e1e;
    box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.5);
    color: #FFFFFF; }
  .public-page-widget__search-row {
    color: #FFFFFF; }
  .public-page-widget__input-block__text {
    background: #2a2a2a;
    border-color: #121212;
    color: #FFFFFF; }
  .public-page__song-info__artist {
    color: #FFFFFF; }
  .public-page__song-info__title {
    color: #CACACA; }
  .public-page__song-info__artist-short {
    color: #FFFFFF; }
  .public-page-widget__song-search-item {
    color: #FFFFFF;
    border-color: #555555; } }

.public-page_dark_white-color {
  .public-page-button:hover {
    color: #000000;
    .public-page-button-icon__path {
      fill: #000000; } }
  .public-page-button--colored-hover:hover {
    color: #FFFFFF;
    .public-page-button-icon__path {
      fill: #FFFFFF; } }
  .public-page-widget__input-block__button:hover {
    color: #000000; }
  .public-page-widget__results-block-item-button:hover {
    color: #000000; }
  .public-page-widget__song-search-item:hover {
    color: #000000; } }

