.public-page-widget {}

.public-page-widget__title {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 700;
  @media (min-width: $xs) {
    font-size: 24px;
    line-height: 29px; } }

.public-page-widget__radio-logo {
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px; }

.public-page-widget__radio-logo__image {
  width: 120px;
  height: 120px;
  border-radius: 12px; }

.public-page-widget__share-text {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 300; }

.public-page-widget__share-group {
  display: flex;
  align-items: center;
  justify-content: center; }

.public-page-widget__share-button {
  width: 100px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 8px;
  font-size: 22px;
  transition: all 0.5s;
  text-decoration: none;
  @media (max-width: $xs) {
    font-size: 18px; }
  &:last-child {
    margin-right: 0px; } }

.public-page-widget__share-button--vk {
  color: #0077FF;
  border: 1px solid #0077FF;
  &:hover {
    background: #0077FF;
    color: #ffffff; } }

.public-page-widget__share-button--fb {
  color: #3569DA;
  border: 1px solid #3569DA;
  &:hover {
    background: #3569DA;
    color: #ffffff; } }

.public-page-widget__share-button--tw {
  color: #21A1F0;
  border: 1px solid #21A1F0;
  &:hover {
    background: #21A1F0;
    color: #ffffff; } }

.public-page-widget__share-button--ok {
  color: #ED8111;
  border: 1px solid #ED8111;
  &:hover {
    background: #ED8111;
    color: #ffffff; } }

.public-page-widget__share-button--tg {
  color: #0088cc;
  border: 1px solid #0088cc;
  &:hover {
    background: #0088cc;
    color: #ffffff; } }


.public-page-widget__song-logo {
  margin-top: 32px;
  text-align: center; }

.public-page-widget__song-logo-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 16px; }

.public-page-widget__song-artist {
  font-size: 18px;
  line-height: 22px;
  text-align: center; }

.public-page-widget__song-artist-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  margin-bottom: 16px;
  text-align: center; }

.public-page-widget__song-search-title {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  text-align: center; }

.public-page-widget__song-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 6px;
  flex-wrap: wrap;
  @media (min-width: $sm) {
    flex-wrap: nowrap; }
  &:last-child {
    margin-bottom: 0; } }

.public-page-widget__song-search-item {
  border: 1px solid #CACACA;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 130px;
  margin-bottom: 6px;
  cursor: pointer;
  transition: all 0.5s;
  color: #000000;
  text-decoration: none;
  @media (min-width: $sm) {
    margin-bottom: 0px; }
  @media (min-width: $md) {
    min-width: 146px; }
  &:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color); } }

.public-page-widget__search-row {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  min-width: 520px;
  @media (min-width: $xs) {
    font-size: 16px;
    line-height: 19px; }
  &:last-child {
    margin-bottom: 0; } }

.public-page-widget__search-row__item {
  font-weight: 300;
  cursor: pointer;
  padding: 3px;
  &:hover {
    color: var(--primary-color); }
  &:last-child {
    margin-right: 0px; } }

.public-page-widget__search-row__item--active {
  font-weight: 500; }

.public-page-widget__input-block {
  margin-top: 16px;
  display: flex;
  margin-bottom: 16px;
  @media (min-width: $xs) {
    margin-top: 28px;
    margin-bottom: 32px; }
  input {
    font-size: 14px;
    @media (min-width: $xs) {
      font-size: 16px; } } }

.public-page-widget__input-block__text {
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  flex-grow: 1;
  height: 48px;
  padding: 0 18px; }

.public-page-widget__input-block__button {
  height: 38px;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 118px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  cursor: pointer;
  @media (min-width: $xs) {
    height: 48px; }
  &:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color); } }

.public-page-widget__results-block {
  margin-bottom: 16px; }

.public-page-widget__results-block-title {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  @media (min-width: $xs) {
    font-size: 16px;
    line-height: 19px; } }

.public-page-widget__results-block-item {
  display: flex;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  @media (min-width: $xs) {
    font-size: 16px;
    line-height: 19px; }
  &:hover {
    color: var(--primary-color); } }

.public-page-widget__results-block-item__primary-black {
  &:hover {
    color: #888; } }

.public-page-widget__results-block-item-song {
  flex-grow: 1;
  margin-bottom: 8px;
  font-weight: 300;
  padding-right: 12px; }

.public-page-widget__results-block-item-button {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  height: 20px;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 15px;
  color: #888888;
  cursor: pointer;
  transition: 0.5s all;
  position: relative;
  @media (min-width: $xs) {
    width: 80px;
    height: 24px;
    font-size: 14px;
    line-height: 17px; }
  &:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color); } }

.public-page-widget__footer-logo {
  margin: 44px auto 0 auto;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  @media (min-width: $xs) {
    margin: 64px auto 0 auto; } }

.public-page-widget__comment-block {
  textarea {
    font-size: 14px;
    @media (min-width: $xs) {
      font-size: 16px; } } }

.public-page-widget__comment-block-label {
  text-align: center;
  font-weight: 300;
  margin-bottom: 12px; }

.public-page-widget__comment-block-text {
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px;
  height: 90px;
  width: 100%; }

.public-page-widget__comment-block-button-block {
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
  @media (min-width: $xs) {
    font-size: 16px; } }

.public-page-widget__comment-block-info {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px; }

.public-page-widget__input-cancel {
  cursor: pointer;
  font-weight: 300;
  display: inline-flex;
  &:hover {
    color: var(--primary-color); } }

.public-page-widget__scrollable-body {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 4px;
    background-color: #EBEBEB; }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #EBEBEB; }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #CACACA; } }

.public-page-widget-horizontal-scroll {
  overflow: auto; }
