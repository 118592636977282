.public-page-footer {
  padding: 64px 0 128px;
  text-align: center; }

.public-page-footer-link {
  display: inline-block;
  text-decoration: none; }

.public-page-footer__text {
  font-size: 14px;
  line-height: 17px;
  color: #444444;
  margin-top: 8px; }
