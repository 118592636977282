.public-page-content {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-direction: column;
  @media (min-width: $md) {
    flex-direction: row; } }

.public-page-content__block--left {
  @media (min-width: $md) {
    width: calc(5/12 * 100% - 8px); } }


.public-page-content__block--right {
  margin-top: 16px;
  @media (min-width: $md) {
    margin-top: 0px;
    width: calc(7/12 * 100% - 8px); } }

.public-page-content__block--centered {
  width: 100%;
  margin: 0 auto; }

.public-page-content__block-item {
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 32px 16px;
  color: #000000;
  @media (min-width: $xs) {
    padding: 32px;
    border: 1px solid #DADADA;
    border-radius: 8px; } }


.public-page-content__block-title {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  font-weight: 700;
  @media (min-width: $xs) {
    font-size: 18px; } }

.public-page-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  transition: 0.5s all;
  font-weight: 500;
  @media (min-width: $xs) {
    margin-right: 16px; }
  &:last-child {
    margin-right: 0px; } }

.public-page-content__block-title__text--active {
  color: #000000;
  font-weight: 700; }
