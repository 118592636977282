.public-page__modal {
  position: fixed;
  width: 100%;
  height: calc(100vh - 76px);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999; }

.public-page__modal-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.public-page__modal__content {
  background: #FFFFFF;
  box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 100%;
  margin: 0 4px;
  max-width: 700px;
  max-height: 80%;
  overflow: auto;
  padding: 32px 16px;
  position: relative;
  @media (min-width: $xs) {
    width: 75%;
    padding: 32px;
    margin: 0; } }

.public-page__modal__content__close {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #CACACA;
  cursor: pointer; }

.public-page__modal__content__close__icon {
  &:hover {
    .public-page__modal__content__close__icon__path {
      fill: var(--primary-color); } } }

.public-page__modal__content__close__icon__path {
  fill: #CACACA;
  transition: all 0.5s; }
