.public-page-button {
  height: 36px;
  border: 1px solid #888888;
  box-sizing: border-box;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  min-width: 140px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s all;
  vertical-align: bottom;
  @media (min-width: $md) {
    display: flex;
    vertical-align: auto; }
  &:hover {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    .public-page-button-icon__path {
      fill: #ffffff; } } }

.public-page-button--inline {
  display: inline-flex; }

.public-page-button-icon {
  margin-right: 12px; }

.public-page-button-icon__path {
  transition: all 0.5s; }

.public-page-button--colored-hover {
  &:hover {
    background: linear-gradient(89.84deg, #4C66BE 0.03%, #E84086 99.97%);
    color: #ffffff;
    border: 1px transparent; } }
