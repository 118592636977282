.public-page__customize-banner {
  font-family: 'Museo Sans', sans-serif;
  padding: 16px;
  background: linear-gradient(88.94deg, #543396 0.03%, #E84086 99.97%);
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 1px;
  @media (min-width: $sm) {
    padding: 9px; } }

.public-page__customize-banner__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: $sm) {
    flex-direction: row;
    align-items: center; } }

.public-page__customize-banner__content__text {
  @media (min-width: $sm) {
    flex-grow: 1; } }

.public-page__customize-banner__content__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  color: #ffffff;
  border-radius: 4px;
  height: 36px;
  min-width: 105px;
  transition: all 0.5s;
  cursor: pointer;
  text-decoration: none;
  margin-top: 8px;
  @media (min-width: $sm) {
    margin-top: 0px; }
  &:hover {
    background: #ffffff;
    color: #000000; } }
