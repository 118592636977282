.public-page__player {
  width: 100%;
  height: 56px;
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  @media (min-width: $md) {
    height: 76px; }
  .public-page-container {
    height: 100%; } }

.public-page__player__wrapper {
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 100%;
  @media (min-width: $xs) {
    padding: 0; } }

.public-page__player__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  @media (min-width: $md) {
    width: 60px;
    height: 60px;
    margin-right: 16px; } }

.public-page__player__primary-controls {
  color: #c4c4c4;
  font-size: 24px;
  @media (min-width: $md) {
    margin-right: 16px; } }

.public-page__player__secondary-controls {
  color: #c4c4c4;
  font-size: 24px;
  position: relative;
  display: flex;
  align-items: center;
  @media (min-width: $md) {
    margin-left: 16px; } }

.public-page__player__info {
  min-width: 0;
  flex-grow: 1; }

.public-page__player__info-artist {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  @media (min-width: $xs) {
    font-size: 16px; } }

.public-page__player__info-title {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: $xs) {
    font-size: 16px; } }

.public-page__player__volume {
  position: absolute;
  bottom: 65px;
  right: 0;
  width: 44px;
  height: 140px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px; }

.public-page__player__volume__triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #ffffff transparent transparent transparent;
  bottom: -8px;
  margin: 0 auto; }


.public-page__player-control {
  cursor: pointer;
  transition: 0.5s all;
  display: inline-flex;
  padding: 16px;
  &:first-child {
    margin-left: 0; }
  &:hover {
    .public-page__player-control__path {
      fill: var(--primary-color); } } }

.public-page__player-control__path {
  transition: all 0.5s;
  fill: #CACACA; }

.public-page__player-control--share {
  display: none;
  @media (min-width: $md) {
    display: block; } }

.public-page__player__button {
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 2px;
  color: #888888;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  padding: 0px 6px;
  min-width: 38px;
  transition: 0.5s; }

.public-page__player__button:hover {
  color: #000000;
  border-color: #888888; }

.public-page__player__streams {
  position: absolute;
  bottom: 65px;
  left: -3px;
  width: 76px;
  padding: 16px 0px;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex; }

.public-page__player__streams__item {
  font-size: 14px;
  line-height: 16px;
  color: #888888;
  margin-bottom: 16px;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: var(--primary-color); }
  &:last-child {
    margin-bottom: 0px; } }

.public-page__player__streams__item--active {
  color: #000000; }
