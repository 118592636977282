.public-page {
  background: #efefef;
  font-family: 'Museo Sans', sans-serif;
  @media (min-width: $xs) {
    padding-top: 15px; } }

.public-page-container {
  margin: 0 auto;
  @media (min-width: $xs) {
    padding: 0 16px; }
  @media (min-width: $md) {
    padding: 0;
    width: 928px; }
  @media (min-width: $lg) {
    width: 1220px; } }

.public-page__header {
  background: #ffffff;
  padding: 32px 16px;
  position: relative;
  overflow: hidden;
  @media (min-width: $xs) {
    padding: 32px;
    border-radius: 12px;
    border: 1px solid #DADADA; } }

.public-page__header--inversed {
  .public-page-button {
    border: 1px solid rgba(202, 202, 202, 0.5);
    color: #ffffff; }
  .public-page__header-content__item,
  .public-page__header-content__item__description,
  .public-page__header-content__info-block-link,
  .public-page__header-content__locales__item--active {
    color: #ffffff; } }

.public-page__header-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.5s;
  @media (min-width: $xs) {
    border-radius: 12px; } }

.public-page__header-image--blured {
  filter: blur(120px) saturate(160%); }

.public-page__header-gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 59.83%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 1;
  @media (min-width: $xs) {
    border-radius: 12px; } }

.public-page__header-content {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  @media (min-width: $sm) {
    flex-direction: row;
    flex-wrap: wrap; }
  @media (min-width: $md) {
    flex-wrap: nowrap; } }

.public-page__header-content__locales {
  position: absolute;
  top: 0;
  right: 0px; }

.public-page__header-content__locales__item {
  cursor: pointer;
  font-size: 12px;
  padding: 8px;
  color: #CACACA;
  display: inline-block;
  transition: all 0.5s;
  &:first-child {
    margin-left: 0px; } }

.public-page__header-content__locales__item--active {
  color: #000000; }

.public-page__header-content__item {
  margin-bottom: 16px;
  color: #000000;
  text-align: center;
  @media (min-width: $xs) {
    text-align: left;
    margin-bottom: 0px;
    margin-right: 32px; } }

.public-page__header-content__item--with-hover {
  position: relative;
  &:hover {
    .public-page__header-content__item__play {
      opacity: 1; } } }

.public-page__header-content__item__play {
  position: absolute;
  top: 0;
  left: calc(50% - 60px);
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  @media (min-width: $xs) {
    left: 0; } }

.public-page__header-content__item__play__circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;
  &--inversed {
    background: #ffffff; }
  @media (min-width: $md) {
    width: 60px;
    height: 60px; } }

.public-page__header-content__item__play__circle-control {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  transform: scale(0.7);
  &:hover {
    transform: scale(0.8); }
  @media (min-width: $xs) {
    transform: scale(1);
    &:hover {
      transform: scale(1.1); } } }

.public-page__header-content__item__play__circle-control__right {
  margin-left: 2px; }

.public-page__header-content__item__play__circle-control-path {
  fill: #ffffff; }

.public-page__header-content__item__play__circle--inversed {
  .public-page__header-content__item__play__circle-control-path {
    fill: #000000; } }

.public-page__header-content__item--central {
  flex-grow: 1;
  @media (min-width: $sm) {
    max-width: calc(100% - 240px); }
  @media (min-width: $md) {
    max-width: none; } }

.public-page__header-content__item--buttons {
  margin-bottom: 0px;
  @media (min-width: $xs) {
    margin-right: 0px;
    min-width: 166px; }
  @media (min-width: $sm) {
    align-self: flex-end;
    margin-left: 152px; }
  @media (min-width: $md) {
    margin-left: 0px; } }


.public-page__header-content__item__logo, .public-page__header-content__item__play {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  @media (min-width: $md) {
    width: 160px;
    height: 160px; } }

.public-page__header-content__item__title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
  font-weight: 700;
  @media (min-width: $xs) {
    font-size: 30px; } }

.public-page__header-content__item__description {
  font-size: 14px;
  color: #444444;
  line-height: 20px;
  display: none;
  @media (min-width: $xs) {
    display: block; } }

.public-page__header-content__item__more {
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer; }

.public-page__header-content__info-block {
  margin-top: 32px; }

.public-page__header-content__info-block--only-xxs {
  @media (min-width: $xs) {
    display: none; } }

.public-page__header-content__info-block-title {
  font-size: 14px;
  line-height: 20px;
  color: #888888; }

.public-page__header-content__info-block-content {
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px; }

.public-page__header-content__info-block-content__links {
  a {
    text-decoration: none;
    color: #ffffff;
    &:hover {
      color: #cacaca;
      opacity: 80%; } } }

.public-page__header-content__info-block-content__links-colored {
  a {
    color: #000000;
    &:hover {
      color: var(--primary-color); } } }


.public-page__header-content__info-block-link {
  text-decoration: none;
  color: #000000;
  &:hover {
    color: #cacaca;
    opacity: 80%; } }

.public-page__header-content__info-block-link__colored {
  &:hover {
    color: var(--primary-color); } }

.public-page__header-content__info-block-social {
  font-size: 16px;
  color: #CACACA;
  margin-right: 16px;
  text-decoration: none;
  &:last-child {
    margin-right: 0px; }
  &:hover {
    color: var(--primary-color); } }

.public-page__header-content__social {
  &:hover {
    .public-page__header-content__social__path {
      fill: var(--primary-color); } } }

.public-page__header-content__social__path {
  fill: #CACACA;
  transition: all 0.5s; }
