.public-page__song {
  display: flex;
  align-items: center;
  margin-bottom: 16px; }

.public-page__song-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 16px; }

.public-page__song-info {
  flex-grow: 1;
  text-align: left;
  min-width: 0; }

.public-page__song-info__artist-short {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300; }

.public-page__song-info__artist {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700; }

.public-page__song-info__title {
  font-size: 14px;
  line-height: 17px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.public-page__song-time {
  font-size: 10px;
  line-height: 12px;
  color: #888888;
  margin-left: 16px;
  position: relative; }

.public-page__song-time__zoom {
  position: absolute;
  cursor: pointer;
  top: -10px;
  left: -20px; }

