.public-page {
  background: #efefef;
  font-family: 'Museo Sans', sans-serif;
}

@media (min-width: 576px) {
  .public-page {
    padding-top: 15px;
  }
}

.public-page-container {
  margin: 0 auto;
}

@media (min-width: 576px) {
  .public-page-container {
    padding: 0 16px;
  }
}

@media (min-width: 992px) {
  .public-page-container {
    padding: 0;
    width: 928px;
  }
}

@media (min-width: 1280px) {
  .public-page-container {
    width: 1220px;
  }
}

.public-page__header {
  background: #ffffff;
  padding: 32px 16px;
  position: relative;
  overflow: hidden;
}

@media (min-width: 576px) {
  .public-page__header {
    padding: 32px;
    border-radius: 12px;
    border: 1px solid #DADADA;
  }
}

.public-page__header--inversed .public-page-button {
  border: 1px solid rgba(202, 202, 202, 0.5);
  color: #ffffff;
}

.public-page__header--inversed .public-page__header-content__item,
.public-page__header--inversed .public-page__header-content__item__description,
.public-page__header--inversed .public-page__header-content__info-block-link,
.public-page__header--inversed .public-page__header-content__locales__item--active {
  color: #ffffff;
}

.public-page__header-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.5s;
}

@media (min-width: 576px) {
  .public-page__header-image {
    border-radius: 12px;
  }
}

.public-page__header-image--blured {
  filter: blur(120px) saturate(160%);
}

.public-page__header-gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 59.83%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 1;
}

@media (min-width: 576px) {
  .public-page__header-gradient {
    border-radius: 12px;
  }
}

.public-page__header-content {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

@media (min-width: 768px) {
  .public-page__header-content {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .public-page__header-content {
    flex-wrap: nowrap;
  }
}

.public-page__header-content__locales {
  position: absolute;
  top: 0;
  right: 0px;
}

.public-page__header-content__locales__item {
  cursor: pointer;
  font-size: 12px;
  padding: 8px;
  color: #CACACA;
  display: inline-block;
  transition: all 0.5s;
}

.public-page__header-content__locales__item:first-child {
  margin-left: 0px;
}

.public-page__header-content__locales__item--active {
  color: #000000;
}

.public-page__header-content__item {
  margin-bottom: 16px;
  color: #000000;
  text-align: center;
}

@media (min-width: 576px) {
  .public-page__header-content__item {
    text-align: left;
    margin-bottom: 0px;
    margin-right: 32px;
  }
}

.public-page__header-content__item--with-hover {
  position: relative;
}

.public-page__header-content__item--with-hover:hover .public-page__header-content__item__play {
  opacity: 1;
}

.public-page__header-content__item__play {
  position: absolute;
  top: 0;
  left: calc(50% - 60px);
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

@media (min-width: 576px) {
  .public-page__header-content__item__play {
    left: 0;
  }
}

.public-page__header-content__item__play__circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;
}

.public-page__header-content__item__play__circle--inversed {
  background: #ffffff;
}

@media (min-width: 992px) {
  .public-page__header-content__item__play__circle {
    width: 60px;
    height: 60px;
  }
}

.public-page__header-content__item__play__circle-control {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  transform: scale(0.7);
}

.public-page__header-content__item__play__circle-control:hover {
  transform: scale(0.8);
}

@media (min-width: 576px) {
  .public-page__header-content__item__play__circle-control {
    transform: scale(1);
  }
  .public-page__header-content__item__play__circle-control:hover {
    transform: scale(1.1);
  }
}

.public-page__header-content__item__play__circle-control__right {
  margin-left: 2px;
}

.public-page__header-content__item__play__circle-control-path {
  fill: #ffffff;
}

.public-page__header-content__item__play__circle--inversed .public-page__header-content__item__play__circle-control-path {
  fill: #000000;
}

.public-page__header-content__item--central {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .public-page__header-content__item--central {
    max-width: calc(100% - 240px);
  }
}

@media (min-width: 992px) {
  .public-page__header-content__item--central {
    max-width: none;
  }
}

.public-page__header-content__item--buttons {
  margin-bottom: 0px;
}

@media (min-width: 576px) {
  .public-page__header-content__item--buttons {
    margin-right: 0px;
    min-width: 166px;
  }
}

@media (min-width: 768px) {
  .public-page__header-content__item--buttons {
    align-self: flex-end;
    margin-left: 152px;
  }
}

@media (min-width: 992px) {
  .public-page__header-content__item--buttons {
    margin-left: 0px;
  }
}

.public-page__header-content__item__logo, .public-page__header-content__item__play {
  width: 120px;
  height: 120px;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .public-page__header-content__item__logo, .public-page__header-content__item__play {
    width: 160px;
    height: 160px;
  }
}

.public-page__header-content__item__title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
  font-weight: 700;
}

@media (min-width: 576px) {
  .public-page__header-content__item__title {
    font-size: 30px;
  }
}

.public-page__header-content__item__description {
  font-size: 14px;
  color: #444444;
  line-height: 20px;
  display: none;
}

@media (min-width: 576px) {
  .public-page__header-content__item__description {
    display: block;
  }
}

.public-page__header-content__item__more {
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.public-page__header-content__info-block {
  margin-top: 32px;
}

@media (min-width: 576px) {
  .public-page__header-content__info-block--only-xxs {
    display: none;
  }
}

.public-page__header-content__info-block-title {
  font-size: 14px;
  line-height: 20px;
  color: #888888;
}

.public-page__header-content__info-block-content {
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
}

.public-page__header-content__info-block-content__links a {
  text-decoration: none;
  color: #ffffff;
}

.public-page__header-content__info-block-content__links a:hover {
  color: #cacaca;
  opacity: 80%;
}

.public-page__header-content__info-block-content__links-colored a {
  color: #000000;
}

.public-page__header-content__info-block-content__links-colored a:hover {
  color: var(--primary-color);
}

.public-page__header-content__info-block-link {
  text-decoration: none;
  color: #000000;
}

.public-page__header-content__info-block-link:hover {
  color: #cacaca;
  opacity: 80%;
}

.public-page__header-content__info-block-link__colored:hover {
  color: var(--primary-color);
}

.public-page__header-content__info-block-social {
  font-size: 16px;
  color: #CACACA;
  margin-right: 16px;
  text-decoration: none;
}

.public-page__header-content__info-block-social:last-child {
  margin-right: 0px;
}

.public-page__header-content__info-block-social:hover {
  color: var(--primary-color);
}

.public-page__header-content__social:hover .public-page__header-content__social__path {
  fill: var(--primary-color);
}

.public-page__header-content__social__path {
  fill: #CACACA;
  transition: all 0.5s;
}

.public-page-button {
  height: 36px;
  border: 1px solid #888888;
  box-sizing: border-box;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  min-width: 140px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s all;
  vertical-align: bottom;
}

@media (min-width: 992px) {
  .public-page-button {
    display: flex;
    vertical-align: auto;
  }
}

.public-page-button:hover {
  color: #ffffff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.public-page-button:hover .public-page-button-icon__path {
  fill: #ffffff;
}

.public-page-button--inline {
  display: inline-flex;
}

.public-page-button-icon {
  margin-right: 12px;
}

.public-page-button-icon__path {
  transition: all 0.5s;
}

.public-page-button--colored-hover:hover {
  background: linear-gradient(89.84deg, #4C66BE 0.03%, #E84086 99.97%);
  color: #ffffff;
  border: 1px transparent;
}

.public-page-content {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-direction: column;
}

@media (min-width: 992px) {
  .public-page-content {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .public-page-content__block--left {
    width: calc(5/12 * 100% - 8px);
  }
}

.public-page-content__block--right {
  margin-top: 16px;
}

@media (min-width: 992px) {
  .public-page-content__block--right {
    margin-top: 0px;
    width: calc(7/12 * 100% - 8px);
  }
}

.public-page-content__block--centered {
  width: 100%;
  margin: 0 auto;
}

.public-page-content__block-item {
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 32px 16px;
  color: #000000;
}

@media (min-width: 576px) {
  .public-page-content__block-item {
    padding: 32px;
    border: 1px solid #DADADA;
    border-radius: 8px;
  }
}

.public-page-content__block-title {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  font-weight: 700;
}

@media (min-width: 576px) {
  .public-page-content__block-title {
    font-size: 18px;
  }
}

.public-page-content__block-title__text {
  color: #888888;
  cursor: pointer;
  margin-right: 8px;
  transition: 0.5s all;
  font-weight: 500;
}

@media (min-width: 576px) {
  .public-page-content__block-title__text {
    margin-right: 16px;
  }
}

.public-page-content__block-title__text:last-child {
  margin-right: 0px;
}

.public-page-content__block-title__text--active {
  color: #000000;
  font-weight: 700;
}

.public-page__song {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.public-page__song-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 16px;
}

.public-page__song-info {
  flex-grow: 1;
  text-align: left;
  min-width: 0;
}

.public-page__song-info__artist-short {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300;
}

.public-page__song-info__artist {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}

.public-page__song-info__title {
  font-size: 14px;
  line-height: 17px;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.public-page__song-time {
  font-size: 10px;
  line-height: 12px;
  color: #888888;
  margin-left: 16px;
  position: relative;
}

.public-page__song-time__zoom {
  position: absolute;
  cursor: pointer;
  top: -10px;
  left: -20px;
}

.public-page__songs-list {
  text-align: center;
}

.public-page-footer {
  padding: 64px 0 128px;
  text-align: center;
}

.public-page-footer-link {
  display: inline-block;
  text-decoration: none;
}

.public-page-footer__text {
  font-size: 14px;
  line-height: 17px;
  color: #444444;
  margin-top: 8px;
}

.public-page__player {
  width: 100%;
  height: 56px;
  position: fixed;
  z-index: 100;
  left: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .public-page__player {
    height: 76px;
  }
}

.public-page__player .public-page-container {
  height: 100%;
}

.public-page__player__wrapper {
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 100%;
}

@media (min-width: 576px) {
  .public-page__player__wrapper {
    padding: 0;
  }
}

.public-page__player__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media (min-width: 992px) {
  .public-page__player__image {
    width: 60px;
    height: 60px;
    margin-right: 16px;
  }
}

.public-page__player__primary-controls {
  color: #c4c4c4;
  font-size: 24px;
}

@media (min-width: 992px) {
  .public-page__player__primary-controls {
    margin-right: 16px;
  }
}

.public-page__player__secondary-controls {
  color: #c4c4c4;
  font-size: 24px;
  position: relative;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .public-page__player__secondary-controls {
    margin-left: 16px;
  }
}

.public-page__player__info {
  min-width: 0;
  flex-grow: 1;
}

.public-page__player__info-artist {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
}

@media (min-width: 576px) {
  .public-page__player__info-artist {
    font-size: 16px;
  }
}

.public-page__player__info-title {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 576px) {
  .public-page__player__info-title {
    font-size: 16px;
  }
}

.public-page__player__volume {
  position: absolute;
  bottom: 65px;
  right: 0;
  width: 44px;
  height: 140px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.public-page__player__volume__triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #ffffff transparent transparent transparent;
  bottom: -8px;
  margin: 0 auto;
}

.public-page__player-control {
  cursor: pointer;
  transition: 0.5s all;
  display: inline-flex;
  padding: 16px;
}

.public-page__player-control:first-child {
  margin-left: 0;
}

.public-page__player-control:hover .public-page__player-control__path {
  fill: var(--primary-color);
}

.public-page__player-control__path {
  transition: all 0.5s;
  fill: #CACACA;
}

.public-page__player-control--share {
  display: none;
}

@media (min-width: 992px) {
  .public-page__player-control--share {
    display: block;
  }
}

.public-page__player__button {
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 2px;
  color: #888888;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  padding: 0px 6px;
  min-width: 38px;
  transition: 0.5s;
}

.public-page__player__button:hover {
  color: #000000;
  border-color: #888888;
}

.public-page__player__streams {
  position: absolute;
  bottom: 65px;
  left: -3px;
  width: 76px;
  padding: 16px 0px;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
}

.public-page__player__streams__item {
  font-size: 14px;
  line-height: 16px;
  color: #888888;
  margin-bottom: 16px;
  cursor: pointer;
  text-align: center;
}

.public-page__player__streams__item:hover {
  color: var(--primary-color);
}

.public-page__player__streams__item:last-child {
  margin-bottom: 0px;
}

.public-page__player__streams__item--active {
  color: #000000;
}

.m-t-8 {
  margin-top: 8px;
}

/* component style */
.vue-slider-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* rail style */
.vue-slider-rail {
  background-color: #ccc;
  border-radius: 15px;
}

/* process style */
.vue-slider-process {
  background-color: var(--primary-color);
  border-radius: 15px;
}

/* mark style */
.vue-slider-mark {
  z-index: 4;
}

.vue-slider-mark:first-child .vue-slider-mark-step, .vue-slider-mark:last-child .vue-slider-mark-step {
  display: none;
}

.vue-slider-mark-step {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.16);
}

.vue-slider-mark-label {
  font-size: 14px;
  white-space: nowrap;
}

/* dot style */
.vue-slider-dot-handle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
}

.vue-slider-dot-handle-focus {
  box-shadow: 0px 0px 1px 2px rgba(52, 152, 219, 0.36);
}

.vue-slider-dot-handle-disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.vue-slider-dot-tooltip-inner {
  font-size: 14px;
  white-space: nowrap;
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  box-sizing: content-box;
}

.vue-slider-dot-tooltip-inner::after {
  content: "";
  position: absolute;
}

.vue-slider-dot-tooltip-inner-top::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-top-color: inherit;
}

.vue-slider-dot-tooltip-inner-bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-bottom-color: inherit;
}

.vue-slider-dot-tooltip-inner-left::after {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-left-color: inherit;
}

.vue-slider-dot-tooltip-inner-right::after {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-right-color: inherit;
}

.vue-slider-dot-tooltip-wrapper {
  opacity: 0;
  transition: all 0.3s;
}

.vue-slider-dot-tooltip-wrapper-show {
  opacity: 1;
}

/*# sourceMappingURL=default.css.map */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.public-page__modal {
  position: fixed;
  width: 100%;
  height: calc(100vh - 76px);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.public-page__modal-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.public-page__modal__content {
  background: #FFFFFF;
  box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 100%;
  margin: 0 4px;
  max-width: 700px;
  max-height: 80%;
  overflow: auto;
  padding: 32px 16px;
  position: relative;
}

@media (min-width: 576px) {
  .public-page__modal__content {
    width: 75%;
    padding: 32px;
    margin: 0;
  }
}

.public-page__modal__content__close {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #CACACA;
  cursor: pointer;
}

.public-page__modal__content__close__icon:hover .public-page__modal__content__close__icon__path {
  fill: var(--primary-color);
}

.public-page__modal__content__close__icon__path {
  fill: #CACACA;
  transition: all 0.5s;
}

.public-page-widget__title {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 700;
}

@media (min-width: 576px) {
  .public-page-widget__title {
    font-size: 24px;
    line-height: 29px;
  }
}

.public-page-widget__radio-logo {
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px;
}

.public-page-widget__radio-logo__image {
  width: 120px;
  height: 120px;
  border-radius: 12px;
}

.public-page-widget__share-text {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 300;
}

.public-page-widget__share-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.public-page-widget__share-button {
  width: 100px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 8px;
  font-size: 22px;
  transition: all 0.5s;
  text-decoration: none;
}

@media (max-width: 576px) {
  .public-page-widget__share-button {
    font-size: 18px;
  }
}

.public-page-widget__share-button:last-child {
  margin-right: 0px;
}

.public-page-widget__share-button--vk {
  color: #0077FF;
  border: 1px solid #0077FF;
}

.public-page-widget__share-button--vk:hover {
  background: #0077FF;
  color: #ffffff;
}

.public-page-widget__share-button--fb {
  color: #3569DA;
  border: 1px solid #3569DA;
}

.public-page-widget__share-button--fb:hover {
  background: #3569DA;
  color: #ffffff;
}

.public-page-widget__share-button--tw {
  color: #21A1F0;
  border: 1px solid #21A1F0;
}

.public-page-widget__share-button--tw:hover {
  background: #21A1F0;
  color: #ffffff;
}

.public-page-widget__share-button--ok {
  color: #ED8111;
  border: 1px solid #ED8111;
}

.public-page-widget__share-button--ok:hover {
  background: #ED8111;
  color: #ffffff;
}

.public-page-widget__share-button--tg {
  color: #0088cc;
  border: 1px solid #0088cc;
}

.public-page-widget__share-button--tg:hover {
  background: #0088cc;
  color: #ffffff;
}

.public-page-widget__song-logo {
  margin-top: 32px;
  text-align: center;
}

.public-page-widget__song-logo-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 16px;
}

.public-page-widget__song-artist {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.public-page-widget__song-artist-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  margin-bottom: 16px;
  text-align: center;
}

.public-page-widget__song-search-title {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  text-align: center;
}

.public-page-widget__song-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .public-page-widget__song-search-wrapper {
    flex-wrap: nowrap;
  }
}

.public-page-widget__song-search-wrapper:last-child {
  margin-bottom: 0;
}

.public-page-widget__song-search-item {
  border: 1px solid #CACACA;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 130px;
  margin-bottom: 6px;
  cursor: pointer;
  transition: all 0.5s;
  color: #000000;
  text-decoration: none;
}

@media (min-width: 768px) {
  .public-page-widget__song-search-item {
    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .public-page-widget__song-search-item {
    min-width: 146px;
  }
}

.public-page-widget__song-search-item:hover {
  color: #ffffff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.public-page-widget__search-row {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  min-width: 520px;
}

@media (min-width: 576px) {
  .public-page-widget__search-row {
    font-size: 16px;
    line-height: 19px;
  }
}

.public-page-widget__search-row:last-child {
  margin-bottom: 0;
}

.public-page-widget__search-row__item {
  font-weight: 300;
  cursor: pointer;
  padding: 3px;
}

.public-page-widget__search-row__item:hover {
  color: var(--primary-color);
}

.public-page-widget__search-row__item:last-child {
  margin-right: 0px;
}

.public-page-widget__search-row__item--active {
  font-weight: 500;
}

.public-page-widget__input-block {
  margin-top: 16px;
  display: flex;
  margin-bottom: 16px;
}

@media (min-width: 576px) {
  .public-page-widget__input-block {
    margin-top: 28px;
    margin-bottom: 32px;
  }
}

.public-page-widget__input-block input {
  font-size: 14px;
}

@media (min-width: 576px) {
  .public-page-widget__input-block input {
    font-size: 16px;
  }
}

.public-page-widget__input-block__text {
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  flex-grow: 1;
  height: 48px;
  padding: 0 18px;
}

.public-page-widget__input-block__button {
  height: 38px;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 118px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  cursor: pointer;
}

@media (min-width: 576px) {
  .public-page-widget__input-block__button {
    height: 48px;
  }
}

.public-page-widget__input-block__button:hover {
  color: #ffffff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.public-page-widget__results-block {
  margin-bottom: 16px;
}

.public-page-widget__results-block-title {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
}

@media (min-width: 576px) {
  .public-page-widget__results-block-title {
    font-size: 16px;
    line-height: 19px;
  }
}

.public-page-widget__results-block-item {
  display: flex;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
}

@media (min-width: 576px) {
  .public-page-widget__results-block-item {
    font-size: 16px;
    line-height: 19px;
  }
}

.public-page-widget__results-block-item:hover {
  color: var(--primary-color);
}

.public-page-widget__results-block-item__primary-black:hover {
  color: #888;
}

.public-page-widget__results-block-item-song {
  flex-grow: 1;
  margin-bottom: 8px;
  font-weight: 300;
  padding-right: 12px;
}

.public-page-widget__results-block-item-button {
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  height: 20px;
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 15px;
  color: #888888;
  cursor: pointer;
  transition: 0.5s all;
  position: relative;
}

@media (min-width: 576px) {
  .public-page-widget__results-block-item-button {
    width: 80px;
    height: 24px;
    font-size: 14px;
    line-height: 17px;
  }
}

.public-page-widget__results-block-item-button:hover {
  color: #ffffff;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.public-page-widget__footer-logo {
  margin: 44px auto 0 auto;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

@media (min-width: 576px) {
  .public-page-widget__footer-logo {
    margin: 64px auto 0 auto;
  }
}

.public-page-widget__comment-block textarea {
  font-size: 14px;
}

@media (min-width: 576px) {
  .public-page-widget__comment-block textarea {
    font-size: 16px;
  }
}

.public-page-widget__comment-block-label {
  text-align: center;
  font-weight: 300;
  margin-bottom: 12px;
}

.public-page-widget__comment-block-text {
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px;
  height: 90px;
  width: 100%;
}

.public-page-widget__comment-block-button-block {
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
}

@media (min-width: 576px) {
  .public-page-widget__comment-block-button-block {
    font-size: 16px;
  }
}

.public-page-widget__comment-block-info {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.public-page-widget__input-cancel {
  cursor: pointer;
  font-weight: 300;
  display: inline-flex;
}

.public-page-widget__input-cancel:hover {
  color: var(--primary-color);
}

.public-page-widget__scrollable-body {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
}

.public-page-widget__scrollable-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #EBEBEB;
}

.public-page-widget__scrollable-body::-webkit-scrollbar {
  width: 6px;
  background-color: #EBEBEB;
}

.public-page-widget__scrollable-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #CACACA;
}

.public-page-widget-horizontal-scroll {
  overflow: auto;
}

.public-page-loader {
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.public-page__customize-banner {
  font-family: 'Museo Sans', sans-serif;
  padding: 16px;
  background: linear-gradient(88.94deg, #543396 0.03%, #E84086 99.97%);
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 1px;
}

@media (min-width: 768px) {
  .public-page__customize-banner {
    padding: 9px;
  }
}

.public-page__customize-banner__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media (min-width: 768px) {
  .public-page__customize-banner__content {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .public-page__customize-banner__content__text {
    flex-grow: 1;
  }
}

.public-page__customize-banner__content__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  color: #ffffff;
  border-radius: 4px;
  height: 36px;
  min-width: 105px;
  transition: all 0.5s;
  cursor: pointer;
  text-decoration: none;
  margin-top: 8px;
}

@media (min-width: 768px) {
  .public-page__customize-banner__content__button {
    margin-top: 0px;
  }
}

.public-page__customize-banner__content__button:hover {
  background: #ffffff;
  color: #000000;
}

.public-page_dark {
  background: #000000;
}

.public-page_dark .public-page__header {
  background: #121212;
  border-color: #262626;
}

.public-page_dark .public-page__header-content__item__description {
  color: #CACACA;
}

.public-page_dark .public-page__header-content__item {
  color: #FFFFFF;
}

.public-page_dark .public-page__header-content__locales__item {
  color: #888888;
}

.public-page_dark .public-page__header-content__locales__item--active {
  color: #FFFFFF;
}

.public-page_dark .public-page__header-content__info-block-link {
  color: #FFFFFF;
}

.public-page_dark .public-page-content__block-item {
  background: #121212;
  border-color: #262626;
  color: #FFFFFF;
}

.public-page_dark .public-page-content__block-title__text {
  color: #CACACA;
}

.public-page_dark .public-page-content__block-title__text--active {
  color: #FFFFFF;
}

.public-page_dark .public-page__player {
  background: #121212;
}

.public-page_dark .public-page-button-icon__path {
  fill: #FFFFFF;
}

.public-page_dark .public-page-footer__text {
  color: #888888;
}

.public-page_dark .public-page__player-control__path {
  fill: #888888;
}

.public-page_dark .public-page__player__button {
  color: #888888;
  border-color: #888888;
}

.public-page_dark .public-page__player__button:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.public-page_dark .public-page__player__streams {
  box-shadow: black 0px 0px 24px;
  background: #2B2B2B;
}

.public-page_dark .public-page__player__volume__triangle {
  border-color: #2B2B2B transparent transparent transparent;
}

.public-page_dark .public-page__player__streams__item--active {
  color: #FFFFFF;
}

.public-page_dark .public-page__player__volume {
  background: #2B2B2B;
  box-shadow: black 0px 0px 24px;
}

.public-page_dark .public-page__player__info-artist {
  color: #FFFFFF;
}

.public-page_dark .public-page__player__info-title {
  color: #CACACA;
}

.public-page_dark .public-page__modal__content {
  background: #1e1e1e;
  box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
}

.public-page_dark .public-page-widget__search-row {
  color: #FFFFFF;
}

.public-page_dark .public-page-widget__input-block__text {
  background: #2a2a2a;
  border-color: #121212;
  color: #FFFFFF;
}

.public-page_dark .public-page__song-info__artist {
  color: #FFFFFF;
}

.public-page_dark .public-page__song-info__title {
  color: #CACACA;
}

.public-page_dark .public-page__song-info__artist-short {
  color: #FFFFFF;
}

.public-page_dark .public-page-widget__song-search-item {
  color: #FFFFFF;
  border-color: #555555;
}

.public-page_dark_white-color .public-page-button:hover {
  color: #000000;
}

.public-page_dark_white-color .public-page-button:hover .public-page-button-icon__path {
  fill: #000000;
}

.public-page_dark_white-color .public-page-button--colored-hover:hover {
  color: #FFFFFF;
}

.public-page_dark_white-color .public-page-button--colored-hover:hover .public-page-button-icon__path {
  fill: #FFFFFF;
}

.public-page_dark_white-color .public-page-widget__input-block__button:hover {
  color: #000000;
}

.public-page_dark_white-color .public-page-widget__results-block-item-button:hover {
  color: #000000;
}

.public-page_dark_white-color .public-page-widget__song-search-item:hover {
  color: #000000;
}
